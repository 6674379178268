import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faHome, faM  } from '@fortawesome/free-solid-svg-icons'; // Import any solid icons you need

const Footer: React.FC = () => {
  return (
<footer>
    <p>Disclaimer:</p>
    <p><i>The capital value of units in the fund can fluctuate and the price of units can go down as well as up and is not guaranteed.</i></p>
            <a className="fa fa-medium" style={{fontSize:"36px", color:'var(--primary-text-color)', marginRight:'1rem', marginLeft:'1rem'}} href="https://app.withmantra.com/market/collection/0xfb035Ab15A174F6c0702901e7b2A24DB8f8cD026?chain_id=2000&amp;auctionType=fixed&amp;sort=2&amp;statuses=created"><FontAwesomeIcon icon={faM} /></a> 
            <a className="fa fa-twitter" style={{fontSize:"36px", color:'var(--primary-text-color)', marginRight:'1rem'}} href="https://twitter.com/DogeToolsDC"><FontAwesomeIcon icon={faXTwitter} /></a> 
            <a className="fa fa-telegram" style={{fontSize:"36px", color:'var(--primary-text-color)', marginRight:'1rem'}} href="https://t.me/DogeToolsDCPortal"><FontAwesomeIcon icon={faTelegram} /></a>
            <a className="fa fa-telegram" style={{fontSize:"36px", color:'var(--primary-text-color)'}} href="https://www.dogetools.dog"><FontAwesomeIcon icon={faHome} /></a>
    <p>© 2024 DogeTools. <i>All rights reserved.</i></p>
  </footer>
  );
};

export default Footer;
