import { useState, useEffect, useCallback } from 'react';
import { ethers, BigNumber } from 'ethers';
import { useAccount } from 'wagmi';
import styled from 'styled-components';
import { getContract2 } from '../lib/contract';
import { useAppContext } from './AppContext';
import Calculator from './calculator';
declare global {
  interface Window {
    ethereum?: any;
  }
}
interface TerminalProps {
  sectionType: 'minting' | 'staking';
}

const GridItem = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;


  img {
    position: relative;
    z-index: 1;
    opacity: 0.6;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &:hover img {
    opacity: 1;
    transform: scale(1.05);
  }
`;
const convertToBigNumberArray = (arr: string[] | null): BigNumber[] => {
  if (arr === null) return [];
  return arr.map(id => BigNumber.from(id));
};
const findNonMatchingElements = (arr1: BigNumber[] | null | undefined, arr2: BigNumber[] | null | undefined): BigNumber[] => {
  const safeArr1 = arr1 ?? [];
  const safeArr2 = arr2 ?? [];

  const set1 = new Set(safeArr1.map(id => id.toString())); // Convert to string for comparison
  const set2 = new Set(safeArr2.map(id => id.toString())); // Convert to string for comparison

  const uniqueToArr1 = safeArr1.filter(item => !set2.has(item.toString()));
  const uniqueToArr2 = safeArr2.filter(item => !set1.has(item.toString()));

  return [...uniqueToArr1, ...uniqueToArr2];
};

const Mints: React.FC<TerminalProps> = ({ sectionType }) =>  {
  const { address, isConnected } = useAccount();
  const { idsOf, setIdsOf } = useAppContext();
  const [balanceOf, setBalanceOf] = useState<number | null>(null);
  const [balanceChange, setBalanceChange] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { difference, setDifference } = useAppContext();
  const [ plotOld, setplotOld] = useState<string[] | null>(null);

  const fetchData = useCallback(async () => {
    if (!isConnected || !address) return;

    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);

      const balanceNew = await contract2.balanceOf(address);
      const balance = parseFloat(balanceNew);
      const tokenIds = await contract2.getTokenIds(address);

// Ensure idsOf is converted to BigNumber[]
const idsOfBigNumbers = convertToBigNumberArray(idsOf);

// Convert both arrays to strings for easy comparison
const idsOfStrings = idsOfBigNumbers.map((id: ethers.BigNumber) => id.toString());
const tokenIdsStrings = tokenIds.map((id: ethers.BigNumber) => id.toString());
const idsOfBigNumbers2 = convertToBigNumberArray(idsOfStrings);

// Find differences by comparing previous IDs to new IDs
const differences = findNonMatchingElements(idsOfBigNumbers2, tokenIdsStrings);

// Convert the differences to a string array if needed
const idsOfStringsNew = differences.map(id => id.toString());

// Log to verify updates and differences
console.log('Previous IDs:', idsOfStrings);
console.log('New IDs:', tokenIdsStrings);
console.log('Difference', difference)
console.log('Updating global IDs after transfer:', tokenIdsStrings);
console.log('Differences:', idsOfStringsNew);
const array1: string[] = tokenIdsStrings;
const array2: string[] = idsOfStringsNew;

// Convert array2 to a Set for efficient lookups
const set2: Set<string> = new Set(array2);

// Filter array1 to remove elements present in set2
      const uniqueToArray1: string[] = array1.filter((item: string) => !set2.has(item));

      setIdsOf(tokenIdsStrings); // Update the global state
      setDifference(idsOfStringsNew);
      setplotOld(uniqueToArray1)
      // Track balance change 
      if (balanceOf !== null) {
        const change = balance - balanceOf;
        setBalanceChange(change > 0 ? `+${change}` : (change < 0 ? `${change}` : null));
      }
      setBalanceOf(balance)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [isConnected, address, balanceOf]);

  useEffect(() => {
    fetchData(); // Fetch data when component mounts or address changes
  }, [fetchData]);

  return (
    <div>
      {sectionType === 'staking' ? (
        <>
      <p style={{ fontSize: '2.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '0' }}>
        Reward <a style={{ color: 'var(--button-color)' }}>Calculator</a>
      </p>
          <Calculator />
        </>
      ) : (
      <>
      <p style={{ fontSize: '2.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '0' }}>
        Wallet <a style={{ color: 'var(--button-color)' }}>Statistics</a>
      </p>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <p style={{ color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '0' }}>
            <p style={{ marginBottom: '1rem' }}>Your balance:</p>
            <a style={{ color: 'var(--button-color)' }}>
              {balanceOf}
            </a>
            <p style={{ marginBottom: '1rem' }}>Your token IDs:</p>
            <div>
              {isConnected && idsOf !== null ? (
                idsOf.length > 0 ? (
                  <span className="ids-list">
                    {idsOf.map((id: string) => (
                      <span key={id} className="id-item">
                        <a className="id-link" href={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${id}.png`}>
                          {id}
                        </a>
                      </span>
                    ))}
                  </span>
                ) : (
                  'No IDs found'
                )
              ) : (
                'Connect to display.'
              )}
            </div>
          </p>
          <p style={{ fontSize: '2.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '2rem' }}>
            <a style={{ color: 'var(--button-color)' }}>NFTs </a>in your wallet:
          </p>
          <div className='balance-grid'>
              { plotOld === null ? (
    'Connect to display.'
  ) : plotOld.length === 0 ? (
    // plotOld is an empty array
    <div className='Container'>
      {difference && difference.length > 0 ? (
        difference.map((id: string) => (
          <GridItem style={{background:''}} key={id}>
            <img style={{opacity:'1'}} src={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${id}.png
`} alt={`ID ${id}`} />
            <p>{id}</p>
          </GridItem>
        ))
      ) : (
        idsOf && idsOf.length > 0 ? (
          idsOf.map((id: string) => (
            <GridItem style={{background:'var(--button-color-off)'}} key={id}>
              <img style={{opacity:'1'}} src={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${id}.png
`} alt={`ID ${id}`} />
              <p>New {id}</p>
            </GridItem>
          ))
        ) : (
          'No new IDs found'
        )
      )}
    </div>
  ) : (
    // plotOld has elements
    <div className='Container'>
      {plotOld.map((id: string) => (
        <GridItem key={id}>
          <img src={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${id}.png
`} alt={`ID ${id}`} />
          <p>{id}</p>
        </GridItem>
      ))}
    </div>
  )}
          </div>
        </>
      )}
      <button onClick={fetchData} style={{ marginTop: '1rem', width: '100%' }}>
        Update Wallet
      </button>
      </>)}
    </div>
  );
};

export default Mints;
