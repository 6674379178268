// components/Terminal.tsx
import { useState, useEffect, useCallback } from 'react';
import { ethers, providers, BigNumber } from 'ethers';
import { getContract, getContract2, getContract3, getContract7 } from '../lib/contract';
import { useAccount, usePublicClient } from 'wagmi';
import CustomAlert from '../CustomAlter'; // Import your custom alert
import Minted from '../utils/minted';
import styled from 'styled-components';
import { useAppContext } from './AppContext';

declare global {
  interface Window {
    ethereum?: any;
  }
}
const hexToFloat = (hex: string): number => {
  // Convert hex to decimal number
  const decimal = ethers.BigNumber.from(hex).toNumber();
  // Return as float (adjust the conversion based on your specific needs)
  return decimal; // Example conversion (you may need a different approach)
};

interface TerminalProps {
  sectionType: 'minting' | 'staking';
}

const numberFormatter2 = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  height: 300px; /* Adjust to fit approximately 1.5 rows */
  margin: 0 auto;
  overflow-y: auto; /* Enable vertical scrolling */
  
  /* WebKit Browsers (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #transparent; /* Track color */
  }

  ::-webkit-scrollbar-thumb {
    background: var(--button-color); /* Color when hovering over the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--button-color-hover); /* Color when hovering over the thumb */
  }

  /* Firefox */
  scrollbar-width: thin; /* Options are auto, thin, or none */
  scrollbar-color: var(--button-color) transparent; /* Thumb and track color */
`;

const GridItem = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */

  &:hover {
    transform: scale(1.02); /* Slightly increase image size on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  }

  img {
    opacity: 0.6;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for image scaling */
  }

  &:hover img {
    opacity: 1;
    transform: scale(1.05); /* Slightly increase image size on hover */
  }
`;


// Styled component with conditional background color
const GridItem2 = styled.div<{ selected: boolean }>`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${props => props.selected ? 'var(--button-hover-color)' : 'transparent'};
  color: ${props => props.selected ? 'var(--background-color)' : 'var(--primary-text-color)'};
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  img {
    opacity: ${props => props.selected ? '1' : '0.6'};
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &:hover img {
    opacity: 1;
    transform: scale(1.05);
  }
`;


const findNonMatchingElements = (arr1: number[] | null | undefined, arr2: number[] | null | undefined): number[] => {
  // Handle null or undefined arrays by defaulting to empty arrays
  const safeArr1 = arr1 ?? [];
  const safeArr2 = arr2 ?? [];

  // Create sets from the arrays
  const set1 = new Set(safeArr1);
  const set2 = new Set(safeArr2);

  // Find elements in safeArr1 that are not in safeArr2
  const uniqueToArr1 = safeArr1.filter(item => !set2.has(item));
  // Find elements in safeArr2 that are not in safeArr1
  const uniqueToArr2 = safeArr2.filter(item => !set1.has(item));

  return [...uniqueToArr1, ...uniqueToArr2];
};
const convertToBigNumberArray = (arr: string[] | null): BigNumber[] => {
  if (arr === null) return [];
  return arr.map(id => BigNumber.from(id));
};


const Terminal: React.FC<TerminalProps> = ({ sectionType }) => {
  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();
  const [marketCap, setMarketCap] = useState<number | null>(null);
  const [tokenPrice, setTokenPrice] = useState<number | null>(null);
  const [alert, setAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(null);
  const [balanceOf, setBalanceOf] = useState<number | null>(null);
  const [idsOf, setIdsOf] = useState<number[] | null>(null);
  const [idsOfNFTsNumbers, setidsOfNumbers] = useState<number[] | null>(null);
  const [idsOfNFTsNumbersUnstake, setidsOfNumbersUnstake] = useState<number[] | null>(null);
  const [kroko, setkroko] = useState<string[]>(['']);
  const [kek, setW] = useState<string[]>(['']);
  const [logs, setLogs] = useState<JSX.Element[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { difference, setDifference } = useAppContext();
  const [ yourRewards, setYourRewards] = useState(0);
  const [ yourStake, setYourstake] = useState<number | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      if (!isConnected || !address) return 'Connect to display';
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract2 = getContract2(signer); // Ensure to define getContract2
        const contract3 = getContract3(signer);
        const balanceOf = await contract2.balanceOf(address);
        const idsOf = await contract2.getTokenIds(address);
        const balanceOfNum = parseFloat(balanceOf.toString());
        const idsOfStrings = idsOf.map((id: any) => id.toString()); // Convert BigNumber to string
        const idsOfNumbers = idsOf.map((id: any) => id.toNumber()); // Convert BigNumber to string
        const getStakeInfo = await contract3.getStakeInfo(address);
        const idsOfNumbersUnstake = getStakeInfo[0].map((id: any) => id.toNumber()); // Convert BigNumber to string
        const idsOfStake = getStakeInfo[0].map((id: any) => id.toString()); // Convert BigNumber to string
        const rewards = parseFloat(getStakeInfo[1].toString());
        const staked = getStakeInfo[0].length;
        setidsOfNumbersUnstake(idsOfNumbersUnstake);
        setidsOfNumbers(idsOfNumbers);
        setYourstake(staked);
        setYourRewards(rewards);
        setW(idsOfStake); 
        setBalanceOf(balanceOfNum);
        convertToBigNumberArray(idsOfStrings);
        setkroko(idsOfStrings); // idsOf is assumed to be an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
 
    fetchData();
  }, [isConnected, address, publicClient]); 

  useEffect(() => {
    const fetchMarketCap = async () => {
      try {
        const { totalSupplyFormatted, costHexFormatted } = await Minted();
        setMarketCap(totalSupplyFormatted);
        setTokenPrice(costHexFormatted);
      } catch (error) {
        console.error('Error fetching remaining supply:', error);
        setMarketCap(0);
        setTokenPrice(0);
      }
    };

    fetchMarketCap();
  }, []);

  const [count, setCount] = useState(1);
  const minCount = 1;
  const maxCount = 20;

  const increment = () => {
    setCount(prevCount => Math.min(prevCount + 1, maxCount));
  };

  const decrement = () => {
    setCount(prevCount => Math.max(prevCount - 1, minCount));
  };


  const fetchData = useCallback(async () => {
    if (!isConnected || !address) return;

    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);

      const balanceNew = await contract2.balanceOf(address);
      const tokenIds = await contract2.getTokenIds(address);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
    }, [isConnected, address]);

  useEffect(() => {
      fetchData(); // Fetch data when component mounts or address changes
    }, [fetchData]);

  const claim = async () => {
      if (!isConnected || !address) return;
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract3 = getContract3(signer);
        console.log('signer')
        const tx = await contract3.claimRewards(); 
        const receipt = await tx.wait(1); 
        const getStakeInfo = await contract3.getStakeInfo(address);
        const rewards = parseFloat(getStakeInfo[1].toString());
        setYourRewards(rewards);
        setAlert({
          type: 'success',
          message: `
            Claimed successfully!<br />
            <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
          `,
        });
      } catch (err) {
        setError('Error fetching transaction logs.');
    }
  };  


  const [selectedTokenIds, setSelectedTokenIds] = useState<number[]>([]);
  const handleTokenClick = (id: string) => {
    const tokenId = Number(id); // Ensure the ID is a number
    setSelectedTokenIds(prevState => {
      // Check if the tokenId is already selected
      if (prevState.includes(tokenId)) {
        // If selected, remove it from the list
        return prevState.filter(item => item !== tokenId);
      } else {
        // If not selected, add it to the list
        return [...prevState, tokenId];
      }
    });
  };

  const [selectedTokenIdsWithdraw, setSelectedTokenIdsWithdraw] = useState<number[]>([]);
  const handleTokenClickWithdraw = (id: string) => {
    const tokenIdWithdraw = Number(id); // Ensure the ID is a number
    setSelectedTokenIdsWithdraw(prevState => {
      // Check if the tokenId is already selected
      if (prevState.includes(tokenIdWithdraw)) {
        // If selected, remove it from the list
        return prevState.filter(item => item !== tokenIdWithdraw);
      } else {
        // If not selected, add it to the list
        return [...prevState, tokenIdWithdraw];
      }
    });
  };
  useEffect(() => {
    console.log('Selected Token IDs:', selectedTokenIdsWithdraw);
  }, [selectedTokenIdsWithdraw]);
  

  useEffect(() => {
    console.log('Selected Token IDs:', selectedTokenIds);
  }, [selectedTokenIds]);
  
  const handleNftStake = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);
      const contract3 = getContract3(signer);
      console.log(selectedTokenIds);
      // Call the stake function
      const tx = await contract3.stake(selectedTokenIds);
      
      console.log('Transaction sent, waiting for confirmation...');
      await tx.wait(1);
      const newbalance = await contract3.getStakeInfo(address);
      const idsOfStake = newbalance[0].map((id: any) => id.toString()); // Convert BigNumber to string
      const staked = newbalance[0].length;
      const balanceOf = await contract2.balanceOf(address);
      const idsOf = await contract2.getTokenIds(address);

      const balanceOfNum = parseFloat(balanceOf.toString());
      const idsOfStrings = idsOf.map((id: any) => id.toString()); // Convert BigNumber to string

      const rewards = parseFloat(newbalance[1].toString());

      setBalanceOf(balanceOfNum);
      convertToBigNumberArray(idsOfStrings);
      setkroko(idsOfStrings); // idsOf is assumed to be an array
      setYourstake(staked);
      setYourRewards(rewards);
      setW(idsOfStake);
      setAlert({
        type: 'success',
        message: `
          Staked successfully!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      });
      console.log('NFTs staked successfully!');
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages

  const handleWithdraw = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);
      const contract3 = getContract3(signer);
      console.log(selectedTokenIdsWithdraw);
      // Call the stake function
      const tx = await contract3.withdraw(selectedTokenIdsWithdraw);
      
      console.log('Transaction sent, waiting for confirmation...');
      await tx.wait(1);
      const newbalance = await contract3.getStakeInfo(address);
      const idsOfStake = newbalance[0].map((id: any) => id.toString()); // Convert BigNumber to string
      const staked = newbalance[0].length;
      const balanceOf = await contract2.balanceOf(address);
      const idsOf = await contract2.getTokenIds(address);

      const balanceOfNum = parseFloat(balanceOf.toString());
      const idsOfStrings = idsOf.map((id: any) => id.toString()); // Convert BigNumber to string

      const rewards = parseFloat(newbalance[1].toString());

      setBalanceOf(balanceOfNum);
      convertToBigNumberArray(idsOfStrings);
      setkroko(idsOfStrings); // idsOf is assumed to be an array
      setYourstake(staked);
      setYourRewards(rewards);
      setW(idsOfStake);
      setAlert({
        type: 'success',
        message: `
          Withdrew successfully!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      });
      console.log('NFTs withdrawal successfully!');
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages

  const handleApprove = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);
      console.log(selectedTokenIds);
      // Call the stake function
      const approve = true; // Set to `true` to grant approval, `false` to revoke
      const tx = await contract2.setApprovalForAll('0xDC9A37881923018773B1061CC87392b00f5c43ab', approve);
      console.log('Transaction sent, waiting for confirmation...');
      await tx.wait();     
      setAlert({
        type: 'success',
        message: `
          Approved successfully!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      }); 
      console.log('NFTs staked successfully!');
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages

  const stake = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = getContract(signer);
      const contract7 = getContract7(signer);
      const balance = await contract7.balanceOf(address);
      const tx = await contract.deposit(balance);
      await tx.wait(1); // Wait for 2 confirmations
      setAlert({
        type: 'success',
        message: `
          Approved successfully!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      });
      console.log('Transaction sent, waiting for confirmation...');
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages

  const handleApproveStake = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract7 = getContract7(signer);
      console.log(selectedTokenIds);
      // Call the stake function
      const contractAddressStaking = '0x7Cc5FB5a99e51B2748A3ee2313E18F4F65B62416';
      const amount = BigNumber.from('115792089237316195423570985008687907853269984665640564039457584007913129639935');

      const tx = await contract7.approve(contractAddressStaking, amount);
      await tx.wait();
      console.log('Transaction sent, waiting for confirmation...');
      setAlert({
        type: 'success',
        message: `
          Approved successfully!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      });
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages
  


  const handleAllNftStake = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);
      const contract3 = getContract3(signer);
      
      console.log(selectedTokenIds);
      // Call the stake function
      const tx = await contract3.stake(idsOfNFTsNumbers);
      
      console.log('Transaction sent, waiting for confirmation...');
      await tx.wait(1);
      const newbalance = await contract3.getStakeInfo(address);
      const idsOfStake = newbalance[0].map((id: any) => id.toString()); // Convert BigNumber to string
      const staked = newbalance[0].length;
      const balanceOf = await contract2.balanceOf(address);
      const idsOf = await contract2.getTokenIds(address);

      const balanceOfNum = parseFloat(balanceOf.toString());
      const idsOfStrings = idsOf.map((id: any) => id.toString()); // Convert BigNumber to string

      const rewards = parseFloat(newbalance[1].toString());
      const idsOfNumbers = idsOf.map((id: any) => id.toNumber()); // Convert BigNumber to string
      console.log(idsOfNumbers)
      console.log(idsOfStrings)
      const getStakeInfo = await contract3.getStakeInfo(address);
      const idsOfNumbersUnstake = getStakeInfo[0].map((id: any) => id.toNumber()); // Convert BigNumber to string
      setidsOfNumbersUnstake(idsOfNumbersUnstake);
      setidsOfNumbers(idsOfNumbers);

      setBalanceOf(balanceOfNum);
      convertToBigNumberArray(idsOfStrings);
      setkroko(idsOfStrings); // idsOf is assumed to be an array
      setYourstake(staked);
      setYourRewards(rewards);
      setW(idsOfStake);
      setAlert({
        type: 'success',
        message: `
          Staked successfully!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      });
      console.log('NFTs staked successfully!');
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages

  const handleAllNftWithdraw = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);
      const contract3 = getContract3(signer);
      console.log(idsOfNFTsNumbersUnstake);
      // Call the stake function
      const tx = await contract3.withdraw(idsOfNFTsNumbersUnstake);
      
      console.log('Transaction sent, waiting for confirmation...');
      await tx.wait(1);
      const newbalance = await contract3.getStakeInfo(address);
      const idsOfStake = newbalance[0].map((id: any) => id.toString()); // Convert BigNumber to string
      const staked = newbalance[0].length;
      const balanceOf = await contract2.balanceOf(address);
      const idsOf = await contract2.getTokenIds(address);

      const balanceOfNum = parseFloat(balanceOf.toString());
      const idsOfStrings = idsOf.map((id: any) => id.toString()); // Convert BigNumber to string
      const idsOfNumbers = idsOf.map((id: any) => id.toNumber()); // Convert BigNumber to string

      const rewards = parseFloat(newbalance[1].toString());
      console.log(idsOfNumbers)
      console.log(idsOfStrings)
      const getStakeInfo = await contract3.getStakeInfo(address);
      const idsOfNumbersUnstake = getStakeInfo[0].map((id: any) => id.toNumber()); // Convert BigNumber to string
      setidsOfNumbersUnstake(idsOfNumbersUnstake);
      setidsOfNumbers(idsOfNumbers);
      setBalanceOf(balanceOfNum);
      convertToBigNumberArray(idsOfStrings);
      setkroko(idsOfStrings); // idsOf is assumed to be an array
      setYourstake(staked);
      setYourRewards(rewards);
      setW(idsOfStake);
      setAlert({
        type: 'success',
        message: `
          Withdrawal successful!<br />
          <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
        `,
      });
      console.log('NFTs staked successfully!');
    } catch (error) {
      console.error('Error staking NFTs:', error);
    }  
  };  // Utility function to safely extract error messages



  const isDisabled = yourRewards <= 0;
  const handleStake = async () => {
    if (!isConnected || !address) return;

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer); // Replace with your ERC-20 contract instance
      const inputField = String(count);
      const amount = ethers.utils.parseEther(inputField);

      const price = await contract2.cost();
      const totalCost = price.mul(count); // Total cost should be price * count
      console.log('Total Cost:', ethers.utils.formatEther(totalCost));

      // Validate the amount
      if (amount.lte(ethers.BigNumber.from(0))) {
        setAlert({ type: 'error', message: 'Error:<br /><br /><span style="color:pink">Amount must be greater than 0.</span>' });
        return;
      }

      const tx = await contract2.mint(count, { value: totalCost });
      const receipt = await tx.wait(1); // Wait for 2 confirmations

      const balanceOf = await contract2.balanceOf(address);
      const tokenids = await contract2.getTokenIds(address);
      const difference = findNonMatchingElements(idsOf, tokenids);
      const balanceOfNum = parseFloat(balanceOf.toString());
      const idsOfStrings = tokenids.map((id: any) => id.toString()); // Convert BigNumber to string
      setBalanceOf(balanceOfNum);
      setIdsOf(idsOfStrings); // idsOf is assumed to be an array
      if (receipt) {
        // Extract and convert the 4th topic from each log
        const extractedLogs = receipt.logs.map((log: providers.Log, index: number) => {
          const topics = log.topics;
          let topic4: number | undefined;
          if (topics.length >= 4) {
            const topic4Hex = topics[3];
            topic4 = hexToFloat(topic4Hex); // Convert to float
          }
          // Construct image URL based on the float value
          const imageUrl = `https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${topic4}.png`; // Adjust URL pattern accordingly
          
          return (
            <GridItem key={index}>
              <img src={imageUrl} alt={`ID ${topic4}`} style={{ width: '100px', height: '100px', opacity:'1'}} />
              <p>{topic4}</p>
            </GridItem>
          );
        });

        setAlert({
          type: 'success',
          message: `
            Staked successfully!<br />
            <strong>Transaction Hash:</strong> <a href="https://explorer.dogechain.dog/tx/${tx.hash}" target="_blank" rel="noopener noreferrer">Tx hash</a>
          `,
        });

        setLogs(extractedLogs);
        setError(null);
      } else {
        setLogs([]);
        setError('Transaction receipt not found.');
      }
    } catch (err) {
      setLogs([]);
      setError('Error fetching transaction logs.');
    }
  };  // Utility function to safely extract error messages
  // Utility function to safely extract error messages
  const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
      return error.message;
    }
    return 'An unknown error occurred.';
  };

  // Function to close alert
  const closeAlert = () => setAlert(null);
  const hasItems = kroko.length > 0;
  const hasStaked = kek.length > 0;
  return (
    <div>
      {sectionType === 'staking' ? (
      <>
      <p style={{ fontSize: '2.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '0' }}>
        User <a style={{ color: 'var(--button-color)' }}>Terminal</a>
      </p>
      <p>
      <a>NFTs available to stake: </a>
      <a style={{ color: 'var(--button-color)' }}>
              {balanceOf}
      </a>
      </p>
      <p>
      <a>Staked NFTs: </a>
      <a style={{ color: 'var(--button-color)' }}>
              {yourStake}
      </a>
      </p>
      <p>
      <a>Your daily rewards: </a>
      <a style={{ color: 'var(--button-color)' }}>
             {numberFormatter2.format(6.96*Number(yourStake))} 
      </a>
      {' '}$DTOOLS
      </p>
      <p>
      <a>Claimable rewards: </a>
      <a style={{ color: 'var(--button-color)' }}>
              {numberFormatter2.format(Number(yourRewards)*1e-18)} 
      </a>
      {' '}$DTOOLS
      </p>
      <a style={{marginTop:'1rem', marginBottom:'1rem'}}>Interact with NFTs:</a>
      <div className='buttoncontainer'>
      <button onClick={claim} disabled={isDisabled}>
                Claim rewards
      </button>
      <button onClick={handleApprove}>
                Approve NFTs
      </button>
      </div>
      <a style={{marginTop:'1rem', marginBottom:'1rem'}}>Interact with staking rewards: </a>
      <div className='buttoncontainer'>
      <button className='mini-button' onClick={stake} disabled={isDisabled}>
                Stake rewards
      </button>
      <button className='mini-button' style={{}} onClick={handleApproveStake}>
                Approve rewards
      </button>
      </div>
      {alert && (
        <CustomAlert 
          type={alert.type} 
          message={alert.message} 
          onClose={closeAlert} 
        />
      )}
      <p style={{color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem'}}>NFTs in your wallet:</p>
      {!hasItems && <p>No IDs found</p>}
      {!isConnected && <p>Connect to display</p>}
      <div className='Container'>
      {hasItems && isConnected && (
      <>
      {kroko.map((id: string) => (
        <GridItem2
         key={id} 
         onClick={() => handleTokenClick(id)}
         selected={selectedTokenIds.includes(Number(id))}
        >
          <img src={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${id}.png`} alt={`ID ${id}`} />
          <p>{id}</p>
        </GridItem2>
      ))}
      </>
      )}
    </div>      
    <div className='buttoncontainer'> 
      <button style={{marginTop:'1rem'}}  onClick={handleNftStake} disabled={selectedTokenIds.length === 0}>
      Stake Selected NFTs
      </button>
      <button style={{marginTop:'1rem'}}  onClick={handleAllNftStake}>
      Stake all NFTs
      </button>
    </div>   
      <p style={{color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '2rem'}}>Staked NFTs for your wallet:</p>
      {!hasStaked && <p>No IDs found</p>}
      {!isConnected && <p>Connect to display</p>}
    <div className='Container'>
      {hasStaked && isConnected && (
      <>
      {kek.map((id: string) => (
        <GridItem2 
        key={id} 
        onClick={() => handleTokenClickWithdraw(id)}
        selected={selectedTokenIdsWithdraw.includes(Number(id))}
       >
        
          <img src={`https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/dogechain/dogetools/${id}.png`} alt={`ID ${id}`} />
          <p>{id}</p>
        </GridItem2>
      ))}
      </>
      )}
    </div>
    <div className='buttoncontainer'> 
      <button style={{marginTop:'1rem'}} onClick={handleWithdraw}>
                Withdraw Selected NFTs
      </button>
      <button style={{marginTop:'1rem'}} onClick={handleAllNftWithdraw}>
                Withdraw all NFTs
      </button>
    </div>  
      </>
      
      ) : (
      <>
      <p style={{ fontSize: '2.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '0' }}>
        User <a style={{ color: 'var(--button-color)' }}>Terminal</a>
      </p>

          <img src="https://mint.dogetools.dog/nftgifnew2.gif" alt="Example" className="hero-image" />

      {alert && (
        <CustomAlert 
          type={alert.type} 
          message={alert.message} 
          onClose={closeAlert} 
        />
      )}
      <div className="counter">
        <button className="counter-button" onClick={decrement}>-</button>
        <span className="counter-value">{count}</span>
        <button className="counter-button" onClick={increment}>+</button>
      </div>
      <p style={{ fontSize: '1.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem' }}>
        Max mint: <a style={{ color: 'var(--button-color)' }}>20</a> NFTs <a style={{ color: 'var(--secondary-text-color)' }}>/ </a>tx
      </p>
      <p style={{ fontSize: '1.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem' }}>
        Cost: <a style={{ color: 'var(--button-color)' }}>{tokenPrice}</a> wDOGE <a style={{ color: 'var(--secondary-text-color)' }}>/ </a>NFT
      </p>
      <div className="buttoncontainer">
        <button onClick={handleStake}>Mint</button>
        <a className="buy" href='https://app.withmantra.com/market/collection/0x92b995f34cbf637d59c66782e90dfec3ce3c202b?chain_id=2000&auctionType=fixed&sort=2&statuses=created'>Buy on Mantra</a>
      </div>
      <p style={{ fontSize: '2.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem' }}>
        Your <a style={{ color: 'var(--button-color)' }}>mints</a>
      </p>
      <div className='balance-grid'>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
         {logs}
        </div>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      </>
    )}
    </div>
  );
};

export default Terminal;
