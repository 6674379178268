import web3 from './web3';
import TokenAbi from '../abis/TokenAbi.json';
import { AbiItem } from 'web3-utils';

const TokenCa = '0x92B995F34cBF637D59c66782E90DFEC3ce3C202B'; // Replace with your contract address

const totalSupplyformat2 = async (): Promise<{ totalSupplyFormatted: number; costHexFormatted: number }> => {
    const tokenContract = new web3.eth.Contract(TokenAbi as AbiItem[], TokenCa);
  
    try {
      const totalSupplyHex: string  = await tokenContract.methods.totalSupply().call();
      const totalSupplyFormatted = parseFloat(totalSupplyHex);
  
      const costHex: string = await tokenContract.methods.cost().call();
      const costHexFormatted = parseFloat(costHex) * 1e-18;

      console.log('HELP', totalSupplyFormatted);
  
      return {totalSupplyFormatted, costHexFormatted};
    } catch (error) {
      console.error('Error fetching total supply:', error);
      return {totalSupplyFormatted: 0, costHexFormatted: 0}; // or handle the error as needed
    }
  };


  export default totalSupplyformat2;