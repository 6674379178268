import web3 from './web3';
import TokenAbi from '../abis/TokenAbi.json';
import { AbiItem } from 'web3-utils';

const TokenCa = '0x92B995F34cBF637D59c66782E90DFEC3ce3C202B'; // Replace with your contract address

const totalSupplyformat = async (): Promise<number> => {
    const tokenContract = new web3.eth.Contract(TokenAbi as AbiItem[], TokenCa);
  
    try {
      const totalSupplyHex: string  = await tokenContract.methods.maxSupply().call();
      const totalSupplyFormatted = parseFloat(totalSupplyHex);
  
      console.log('HELP', totalSupplyFormatted);
  
      return totalSupplyFormatted;
    } catch (error) {
      console.error('Error fetching total supply:', error);
      return 0; // or handle the error as needed
    }
  };


  export default totalSupplyformat;