import React, { useEffect, useState, useCallback } from 'react';
import styles from './CustomAlert.module.css'; // Adjust path as necessary

interface CustomAlertProps {
  type: 'success' | 'error';
  message: string;
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ type, message, onClose }) => {
  const [progress, setProgress] = useState(100);
  const alertClass = type === 'success' ? styles.success : styles.error;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (type === 'success') {
      const timer = setInterval(() => {
        setProgress(prev => {
          if (prev > 0) {
            return prev - 2; // Adjust the decrement as necessary for smoother progress
          } else {
            clearInterval(timer);
            handleClose(); // Use stable handleClose function
            return 0;
          }
        });
      }, 100);

      return () => clearInterval(timer);
    }
  }, [type, handleClose]);

  return (
    <div className={`${styles.alert} ${alertClass}`}>
      <button className={styles.closeButton} onClick={handleClose}>×</button>
      <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
      {type === 'success' && <div className={styles.progressBar} style={{ width: `${progress}%` }} />}
    </div>
  );
};

export default CustomAlert;
