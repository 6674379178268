import React, { useState, useEffect } from 'react';
import '../styles/calculator.css';

const Calculator = () => {
  const [principal, setPrincipal] = useState<number | undefined>(undefined);
  const [days, setDays] = useState<number | undefined>(undefined);
  const [results, setResults] = useState<Record<string, number> | null>(null);

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const dailyRate = 6.96;

  const calculateResults = () => {
    if (principal === undefined || days === undefined) {
      return; // Don't calculate if any value is undefined
    }

    const calculations = {
      '1 day': principal * dailyRate * 1,
      '7 days': principal * dailyRate * 7,
      '30 days': principal * dailyRate * 30,
      '6 months': principal * dailyRate * 180, // Assuming 30 days per month
      '1 year': principal * dailyRate * 365
    };

    setResults(calculations);
  };

  useEffect(() => {
    calculateResults();
  }, [principal, days]);

  const handlePrincipalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = parseInt(value, 10);
    
    if (value === '' || (!isNaN(numberValue) && numberValue >= 0)) {
      setPrincipal(numberValue || undefined);
    }
  };

  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = parseInt(value, 10);
    
    if (value === '' || (!isNaN(numberValue) && numberValue >= 0)) {
      setDays(numberValue || undefined);
    }
  };

  return (
    <div className="container">
      <div className="inputContainer">
        <label className="label">Amount of NFTs:</label>
        <input
          type="number"
          value={principal === undefined ? '' : principal}
          onChange={handlePrincipalChange}
          className="input no-spinner"
          placeholder="Enter NFT amount"
          max="5000"
          step="1" // Only allow whole numbers
          min="0" // Prevent negative numbers
        />
        {principal !== undefined && principal > 5000 && (
          <div style={{color: 'var(--secondary-text-color)', marginBottom: '1rem', fontSize:'1rem'}}>
            The amount exceeds the maximum limit of 5000 NFTs.
          </div>
        )}
      </div>
      <div className="inputContainer">
        <label className="label">Number of days:</label>
        <input
          type="number"
          value={days === undefined ? '' : days}
          onChange={handleDaysChange}
          className="input no-spinner"
          placeholder="Enter number of days"
          step="1" // Only allow whole numbers
          min="0" // Prevent negative numbers
        />
      </div>
      <div className="results">
      <a style={{fontSize:'1.5rem'}}>Simulated Rewards:</a>
      <div style={{marginBlock:'0.5rem'}}>
            Total Amount <a style={{color: 'var(--secondary-text-color)',marginBlock:'0.5rem'}}>(for {days || 0} days)</a>:
            <a style={{color: 'var(--button-color)',marginBlock:'0.5rem'}}>
            {' '}+{numberFormatter.format((principal !== undefined && principal <= 5000 ? (principal || 0) * (dailyRate * (days || 0)) : 0 ))}
            </a>
          </div>
          <div style={{marginBlock:'0.5rem'}}>
            1 <a style={{color: 'var(--secondary-text-color)',marginBlock:'0.5rem'}}>day: </a> 
            <a style={{color: 'var(--button-color)',marginBlock:'0.5rem'}}>
              +{numberFormatter.format((principal !== undefined && principal <= 5000 ? (principal || 0) * (dailyRate * ((days || 0)/(days || 1))) : 0 ))}
            </a>
          </div>
          <div style={{marginBlock:'0.5rem'}}>
            7 <a style={{color: 'var(--secondary-text-color)',marginBlock:'0.5rem'}}>days: </a> 
            <a style={{color: 'var(--button-color)',marginBlock:'0.5rem'}}>
              +{numberFormatter.format((principal !== undefined && principal <= 5000 ? (principal || 0) * (dailyRate * (7 * (days || 0)/(days || 1))) : 0 ))}
            </a>
          </div>
          <div style={{marginBlock:'0.5rem'}}>
            30 <a style={{color: 'var(--secondary-text-color)',marginBlock:'0.5rem'}}>days: </a> 
            <a style={{color: 'var(--button-color)',marginBlock:'0.5rem'}}>
            +{numberFormatter.format((principal !== undefined && principal <= 5000 ? (principal || 0) * (dailyRate * (30 * (days || 0)/(days || 1))) : 0 ))}
            </a>
          </div>
          <div style={{marginBlock:'0.5rem'}}>
            6 <a style={{color: 'var(--secondary-text-color)',marginBlock:'0.5rem'}}>months: </a> 
            <a style={{color: 'var(--button-color)',marginBlock:'0.5rem'}}>
            +{numberFormatter.format((principal !== undefined && principal <= 5000 ? (principal || 0) * (dailyRate * (180 * (days || 0)/(days || 1))) : 0 ))}
            </a>
          </div>
          <div style={{marginBlock:'0.5rem'}}>
            1 <a style={{color: 'var(--secondary-text-color)',marginBlock:'0.5rem'}}>year: </a> 
            <a style={{color: 'var(--button-color)',marginBlock:'0.5rem'}}>
            +{numberFormatter.format((principal !== undefined && principal <= 5000 ? (principal || 0) * (dailyRate * (365 * (days || 0)/(days || 1))) : 0 ))}
            </a>
          </div>
          <div style={{marginBlock:'0.5rem'}}><a style={{color: 'var(--secondary-text-color)',marginBlock:'1rem'}}>[<a style={{color:'var(--primary-text-color)'}}>Rewards</a> in <a style={{color: 'var(--button-color)'}}>$DTOOLS</a>]</a></div>
      </div>
    </div>
  );
};

export default Calculator;
