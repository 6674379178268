import web3 from './web3';
import NFTStakingAbi from '../abis/NFTStakingAbi.json';
import { AbiItem } from 'web3-utils';

const TokenCa = '0xDC9A37881923018773B1061CC87392b00f5c43ab'; // Replace with your contract address

const rewards = async (): Promise<number> => {
    const tokenContract = new web3.eth.Contract(NFTStakingAbi as AbiItem[], TokenCa);
  
    try {
      const totalSupplyHex: string  = await tokenContract.methods.getRewardsPerUnitTime().call();
      const rewardsperTime = parseFloat(totalSupplyHex);
  
      console.log('HELP', rewardsperTime);
  
      return rewardsperTime;
    } catch (error) {
      console.error('Error fetching total supply:', error);
      return 0; // or handle the error as needed
    }
  };


  export default rewards;