import { useAppContext } from './AppContext';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { getContract2 } from '../lib/contract';
import { useAccount, usePublicClient } from 'wagmi';
import CustomAlert from '../CustomAlter'; // Ensure the correct path
import '../styles/Transfer.css'; // Ensure the correct path
// Convert string[] to BigNumber[]


const TokenStats: React.FC = () => {
  const [input, setInput] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [history, setHistory] = useState<{ text: string; txHash?: string; topic4?: number }[]>([]);
  const [alert, setAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const [idError, setIdError] = useState<string | null>(null);
  const { idsOf, setIdsOf } = useAppContext();
  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInput(value);
    validateAddress(value);
  };

  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setId(value);
    validateId(value);
  };

  const handleCloseAlert = () => {
    setAlert(null); // Set alert to null to close it
  };

  const validateAddress = (address: string) => {
    if (address.trim() === '') {
      setInputError(null);
    } else if (address.endsWith(' ')) {
      setInputError('Please remove the empty space from your input.');
    } else if (address.length > 42) {
      const excessLength = address.length - 42;
      setInputError(`Please remove ${excessLength} symbol(s) from your input.`);
    } else if (address.startsWith('0x') && address.length === 42) {
      setInputError(null);
    } else if (address.startsWith('0x')) {
      const remainingLength = 42 - address.length;
      setInputError(`Missing ${remainingLength} symbol(s) to match required length.`);
    } else {
      setInputError('Invalid Ethereum address. Must start with 0x.');
    }
  };
  const validateId = (id: string) => {
    if (id.trim() === '') {
      setIdError(null);
    } else if (id.endsWith(' ')) {
      setInputError('Please remove the empty space from your input.');
    } else if (id.length > 4) {
      const excessLength = id.length - 4;
      setIdError(`Please remove ${excessLength} symbol(s) from your input.`);
    } else if (idsOf && !idsOf.includes(id)) {
      setIdError('Invalid NFT ID. Please enter a valid ID from your list.');
    } else {
      setIdError(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isConnected || !address || !publicClient) return;

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract2 = getContract2(signer);

        const tokenIds = await contract2.getTokenIds(address);
        const idsOfStrings = tokenIds.map((id: ethers.BigNumber) => id.toString());
        setIdsOf(idsOfStrings); // Update the global state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [isConnected, address, publicClient, setIdsOf]);

  const handleTransfer = async () => {
    if (!isConnected || !address) return;

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract2 = getContract2(signer);

      if (!input || !id || inputError || idError) {
        setAlert({ type: 'error', message: 'Please correct the errors before proceeding.' });
        return;
      }

      const tx = await contract2.transferFrom(address, input, id);
      const receipt = await tx.wait(2);

      // Extract the 4th topic from the logs
      const log = receipt.logs[0]; // Assuming the topic is in the first log entry
      const topics = log.topics;
      let topic4: number | undefined;
      if (topics.length >= 4) {
        const topic4Hex = topics[3];
        topic4 = parseInt(topic4Hex, 16);
      }
      const tokenIds = await contract2.getTokenIds(address);
      // Ensure idsOf is converted to BigNumber[]

      const idsOfStrings = tokenIds.map((id: ethers.BigNumber) => id.toString());
      console.log('Updating global IDs after transfer:', idsOfStrings); // Log to verify updates
      setIdsOf(idsOfStrings); // Update the global state
      // Add transaction hash and topic4 to history
      setHistory([...history, {
        text: `Transfer to ${formatAddress(input)} NFT ID ${id}`,
        txHash: receipt.transactionHash,
        topic4
      }]);
      setAlert({ type: 'success', message: 'Transfer successful!' });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      if (errorMessage.includes('user rejected transaction')) {
        setAlert({
          type: 'error',
          message: 'Transaction rejected:<br /><span class="error-detail">You rejected the transaction. Please try again.</span>'
        });
      } else {
        setAlert({
          type: 'error',
          message: `Error during transfer:<br /><span class="error-detail">${errorMessage}</span>`
        });
      }
    }
  };

  const handleSubmit = () => {
    if (input.trim() && id.trim() && !inputError && !idError) {
      handleTransfer();
      setInput('');
      setId('');
    }
  };

  const formatAddress = (address: string) => {
    if (address.length > 8) {
      return `${address.slice(0, 4)}...${address.slice(-4)}`;
    }
    return address;
  };

  return (
    <div className="terminal-container">
      <p className="title">
        <a className="title-link">Transfer</a> center
      </p>
      <p className="subtitle">
        <a className="subtitle-link">Token IDs</a> in your wallet:
      </p>
      <p>
        {isConnected && idsOf !== null ? (
          idsOf.length > 0 ? (
            <span className="ids-list">
              {idsOf.map((id) => (
                <span key={id} className="id-item">
                  <a className="id-link" href={`---`}>
                    {id}
                  </a>
                </span>
              ))}
            </span>
          ) : (
            'No IDs found'
          )
        ) : (
          'Connect to display.'
        )}
      </p>
      <p className="label">Recipient:</p>
      <div className="terminal-input-container">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          placeholder='Enter destination address...'
          className={`terminal-input ${inputError ? 'invalid' : ''}`}
        />
        {inputError && <p className="input-error-message">{inputError}</p>}
      </div>
      <p className="label">ID:</p>
      <div className="terminal-input-container">
        <input
          type="text"
          value={id}
          onChange={handleIdChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          placeholder='Enter NFT ID...'
          className={`terminal-input ${idError ? 'invalid' : ''}`}
        />
        {idError && <p className="input-error-message">{idError}</p>}
        <button
          onClick={handleSubmit}
          className={`terminal-submit-button ${inputError || idError ? 'disabled' : ''}`}
          disabled={!!inputError || !!idError}
        >
          Transfer
        </button>
      </div>
      <p style={{ fontSize: '1.2rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem' }}>Tx history</p>
      <div className="terminal-output">
        <div className="terminal-history">
          {history.map((entry, index) => (
            <div key={index}>
              {`${entry.text}`} <a href={`https://explorer.dogechain.dog/tx/${entry.txHash}`} target="_blank" rel="noopener noreferrer" className="tx-hash">Tx hash</a>
              {entry.topic4 !== undefined && (
                <p className="topic4-info">4th Topic: {entry.topic4}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <p className="reminder">
      <i><a className="reminder-link">Reminder: </a>You can only transfer single NFTs at a time through this.</i>
      <br/>
      <i>You can also mint and tip DTOOLS NFTs through our Telegram <a className="reminder-link" href="https://t.me/DogeToolsNftDCTipBot">bot</a>.</i>
      </p>
      {alert && <CustomAlert type={alert.type} message={alert.message} onClose={handleCloseAlert} />}
    </div>
  );
};

export default TokenStats;
