import React, { useState, useEffect } from 'react';
import './App.css';
import TokenStats from './components/Transfer';
import Navbar from './components/navbar';
import MyChart from './components/MyChart';
import Terminal from './components/terminal';
import QASection from './components/QAsection';
import Footer from './components/Footer';
import Mints from './components/Mints';
import Name from './components/name';
import { AppProvider } from './components/AppContext'; // Ensure correct path
import { useLocation, useNavigate } from 'react-router-dom';
const App: React.FC = () => {
  const [isDark, setIsDark] = useState(false);
  const [selectedSection, setSelectedSection] = useState<'minting' | 'staking'>('minting');


  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Read the query parameter from the URL
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    
    if (section === 'staking' || section === 'minting') {
      setSelectedSection(section as 'minting' | 'staking');
    }
  }, [location.search]);

  const handleSelect = (section: 'minting' | 'staking') => {
    setSelectedSection(section);

    // Update the URL with the new section
    navigate(`?section=${section}`);
  };


  const toggleTheme = () => {
    setIsDark(!isDark);
  };

  const mintingItems = [
    {
      question: 'How much does minting 1 NFT cost?',
      answer: 'The normal price is set to 80wDOGE, however it might be occasionally reduced by team for special events.'
    },
    {
      question: 'How many NFTs are part of the collection?',
      answer: 'Our whole collection consists of 5000 NFTs in total, containg rare NFTs which i.e. feature Marc Cuban or Elon Musk.'
    },
    {
      question: 'What are special Pizza NFTs?',
      answer: 'Every minted NFT that includes a Pizza slice will be rewarded with a reward of $25 worth in wDOGE, as an appreciation towards the guy who ordered Pizza for his BTC in the past.'
    },
    {
      question: 'Can minted NFTs be sold or traded?',
      answer: 'To sell, view or move your NFTs you can visit the leading NFT marketplace <a href="https://app.withmantra.com/market/collection/0x92b995f34cbf637d59c66782e90dfec3ce3c202b?chain_id=2000&auctionType=fixed&sort=2&statuses=created">Mantra</a> on Dogechain.'
    },
    {
    question: 'What other benefits do these NFTs have?',
    answer: 'For once you are able to stake the minted NFTs in order to gain rewards in our $DTOOLS token and beyond that after successfully minting out the whole collection, we will run a $5000 worth of $WDOGE giveaway!'
    },
    // Add more questions and answers here
  ];

  const stakingItems = [
    {
      question: 'How often do you gain rewards?',
      answer: 'Staking rewards are granted every hour for your staked NFTs, you are gaining 1/24th of the daily reward per hour resulting in 6.96 $DTOOLS/day for each individual NFT.'
    },
    {
      question: 'Is there a transfer/staking limit for single transactions or wallets?',
      answer: 'The contract allows staking/unstaking your entire collection in one transaction, neither is there any limitation on how many you can stake.'
    },
    {
      question: 'Should you use multiple wallets or stake your NFTs in one wallet?',
      answer: 'In terms of rewards it does not make any difference, the rewards are summed up for multiple staked NFTs.<br/>However, we occaisonally airdrop NFT stakers depending on staking numbers for an individual address, as we cannot verify the connection between multiple individual addresses.'
    },
    {
      question: 'Can staked NFTs be transfered or traded?',
      answer: 'As you are proceeding with the staking process the transaction will lock them in the staking contract until you decide to withdraw them into your wallet.<br/><br/>Therefore, for the time staking - your NFTs will not be transferable or listable on Marketplaces.'
    },
    // Add more questions and answers here
  ];

  return (
    <AppProvider>
    <div className={`App ${selectedSection}`} data-theme={isDark ? "dark" : "light"}>
      <Navbar isDark={isDark} toggleTheme={toggleTheme} onSelect={handleSelect} />
      <div className="main-content" data-theme={isDark ? "dark" : "light"}>
        <Name sectionType={selectedSection} />
        <div className="grid">
          <div className="item">
            <Terminal data-theme={isDark ? "dark" : "light"} sectionType={selectedSection}/>
          </div>
          <div className="item">
            <MyChart theme={isDark ? "dark" : "light"} sectionType={selectedSection} />
          </div>
          <div className="item">
            <Mints data-theme={isDark ? "dark" : "light"} sectionType={selectedSection}  />
          </div>
          <div className="item">
    <QASection 
      sectionType={selectedSection}
      qaItems={mintingItems} 
      qaItems1={stakingItems} 
    />
          </div>
          <div className="item">
            <TokenStats />
          </div>
        </div>
      </div>
      <div>
        <Footer data-theme={isDark ? "dark" : "light"} />
      </div>
    </div>
    </AppProvider>

  );
};

export default App;
 