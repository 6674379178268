// utils/contract.ts
import { ethers } from 'ethers';
import StakingAbi from '../abis/StakingAbi.json';
import TokenAbi from '../abis/TokenAbi.json';
import NFTStakingAbi from '../abis/NFTStakingAbi.json';

const StakingCa = '0x7Cc5FB5a99e51B2748A3ee2313E18F4F65B62416'; // Replace with your contract address
const TokenCa = '0x92B995F34cBF637D59c66782E90DFEC3ce3C202B'; // Replace with your contract address
const NFTStakingCa = '0xDC9A37881923018773B1061CC87392b00f5c43ab'; 
const TokenCa2 = '0xB9fcAa7590916578087842e017078D7797Fa18D0';

export const getContract = (provider: ethers.providers.Provider | ethers.Signer) => {
  return new ethers.Contract(StakingCa, StakingAbi, provider);
};

export const getContract2 = (provider: ethers.providers.Provider | ethers.Signer) => {
    return new ethers.Contract(TokenCa, TokenAbi, provider);
  };

export const getContract3 = (provider: ethers.providers.Provider | ethers.Signer) => {
    return new ethers.Contract(NFTStakingCa, NFTStakingAbi, provider);
  };

  export const getContract7 = (provider: ethers.providers.Provider | ethers.Signer) => {
    return new ethers.Contract(TokenCa2, TokenAbi, provider);
  };