import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { WalletProvider } from './lib/WalletContext'; // Import WalletProvider
import { WagmiProvider } from 'wagmi'
import { dogechain, mainnet } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode } from 'react'  // Import React and ReactNode
import { AppProvider } from './components/AppContext';
// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = 'e5decdb4b58018fca5a4808be3b3ea07'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, dogechain] as const
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

// 3. Create modal
createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,// Optional - defaults to your Cloud configuration
  themeVariables: {
    '--w3m-color-mix': 'var(--background-color)',
    '--w3m-color-mix-strength': 0,
    '--w3m-accent': 'var(--connect-accent)'
  },
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
    '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f',
    '18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1'
  ],
  chainImages: {
    2000: 'https://dd.dexscreener.com/ds-data/chains/dogechain.png'
  },
  
})
interface Web3ModalProviderProps {
    children: ReactNode;
  }



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
   <AppProvider>
    <QueryClientProvider client={queryClient}>
     <WagmiProvider config={config}>
      <WalletProvider>
      <Router>
       <App />
      </Router>
      </WalletProvider>
     </WagmiProvider>
    </QueryClientProvider>
   </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
