import { FC } from 'react';
import './toggle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
interface ToggleProps {
    handleChange: () => void;
    isChecked: boolean;
}

export const Toggle: FC<ToggleProps> = ({ handleChange, isChecked }) => {
    return (
        <a>
            <input 
              type="checkbox"
              id="check"
              className="toggle"
              onChange={handleChange}
              checked={isChecked}
            />
            <label htmlFor="check" className="toggle-label">
                <FontAwesomeIcon icon={isChecked ? faMoon : faSun} style={{width:'30px'}}/>
            </label>        
        </a>
    );
};
