// src/components/MyChart.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';
import fetchData from '../utils/fetchData';
import totalSupplyformat from '../utils/totalSupply';
import totalSupplyformat2 from '../utils/minted';
import remainingSupply from '../utils/remainingSupply';
import rewardsperTime from '../utils/rewards';
import balance from '../utils/balance';
import staked from '../utils/staked';
import { getContract, getContract2 } from '../lib/contract';
import { useAccount, usePublicClient  } from 'wagmi';
import { ethers, BigNumber } from 'ethers';

interface MyChartProps {
  theme: 'light' | 'dark';
  sectionType: 'minting' | 'staking';
}

const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const numberFormatter2 = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const MyChart: React.FC<MyChartProps> = ({ theme, sectionType }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chartData, setChartData] = useState({
    labels: ['Total Supply', 'Minted', 'Remaining'],
    datasets: [{
      label: 'NFT Stats',
      borderRadius: 10,
      backgroundColor: theme === 'dark' ? 'rgb(255, 36, 109)' : 'rgb(21, 255, 138)',      borderWidth: 1,
      data: [0, 0, 0],
      responsive: true,
    }]
  });
  const [totalSupply, setTotalSupply] = useState<number | null>(null);

  const chartRefStake = useRef<HTMLCanvasElement>(null);
  const [chartDataStake, setChartDataStake] = useState({
    labels: ['Total Supply', 'Minted', 'Staked'],
    datasets: [{
      label: 'Staking Stats',
      borderRadius: 10,
      backgroundColor: theme === 'dark' ? 'rgb(0, 146, 172)' : 'rgb(21, 255, 138)',      borderWidth: 1,
      data: [0, 0, 0],
      responsive: true,
    }]
  });

  useEffect(() => {
    const fetchTotalSupply = async () => {
      try {
        const supply = await totalSupplyformat();
        setTotalSupply(supply);
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };
  
    fetchTotalSupply();
  }, []);

  const [stakedSupply, setStaked] = useState<number | null>(null);
  useEffect(() => {
    const fetchStaked = async () => {
      try {
        const supply = await totalSupplyformat2();
        setStaked(supply.totalSupplyFormatted);
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };
  
    fetchStaked();
  }, []);

  
  const [stakedNFTs, setstakedNFTs] = useState<number | null>(null);
  useEffect(() => {
    const c = async () => {
      try {
        const ca = '0xDC9A37881923018773B1061CC87392b00f5c43ab';
        const supply = await balance(ca);
        setstakedNFTs(supply);
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };
  
    c();
  }, []);

  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();

  const [stakedNFTsU, stakedNFTsUs] = useState<number | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      if (!isConnected || !address || !publicClient) return;
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = getContract(signer); // Ensure to define getContract
        const contract2 = getContract2(signer); // Ensure to define getContract2
        
        const balanceOf = await contract2.balanceOf(address);
        const idsOf = await contract2.getTokenIds(address);
        
        const balanceOfNum = parseFloat(balanceOf.toString());
        const idsOfStrings = idsOf.map((id: any) => id.toString()); // Convert BigNumber to string

        stakedNFTsUs(balanceOfNum);
        stakedNFTsUs(idsOfStrings); // idsOf is assumed to be an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [isConnected, address, publicClient]);


  const [rewardsRemaining, setRewards] = useState<number | null>(null);
  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const supply = await remainingSupply();
        setRewards(supply);
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };
  
    fetchRewards();
  }, []);


  const [dailyRewards, setdailyRewards] = useState<number | null>(null);
  useEffect(() => {
    const rewards = async () => {
      try {
        const supply = await rewardsperTime();
        setdailyRewards(supply);
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };
  
    rewards();
  }, []);

  const [chartInstance, setChartInstance] = useState<Chart | null>(null);
  const [chartInstanceStake, setChartInstanceStake] = useState<Chart | null>(null);

  useEffect(() => {
    const updateChartData = async () => {
      try {
        const data = await fetchData();
        const supplyT = await totalSupplyformat();
        const supplyM = await totalSupplyformat2();
        const supplyR = await remainingSupply();
        const transformedData = {
          totalSupply: supplyT,
          minted: supplyM.totalSupplyFormatted,
          remaining: supplyR,
        };
        
        setChartData(prevData => ({
          ...prevData,
          datasets: [{
            ...prevData.datasets[0],
            data: [transformedData.totalSupply, transformedData.minted, transformedData.remaining],
          }],
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    updateChartData();
  }, []);

  useEffect(() => {
    const updateChartDataStake = async () => {
      try {
        const supplyT = await totalSupplyformat();
        const supplyM = await totalSupplyformat2();
        const supplyS = await staked();
        const transformedData = {
          totalSupply: supplyT,
          minted: supplyM.totalSupplyFormatted,
          staked: supplyS,
        };
        
        setChartDataStake(prevData => ({
          ...prevData,
          datasets: [{
            ...prevData.datasets[0],
            data: [transformedData.totalSupply, transformedData.minted, transformedData.staked],
          }],
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    updateChartDataStake();
  }, []);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    if (!ctx) return;

    // Destroy previous chart instance if it exists
    if (chartInstance) {
      chartInstance.destroy();
    }

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChartInstance(newChart);

    return () => {
      newChart.destroy();
    };
  }, [chartData, sectionType]);

  useEffect(() => {
    const ctxStake = chartRefStake.current?.getContext('2d');
    if (!ctxStake) return;

    // Destroy previous chart instance if it exists
    if (chartInstanceStake) {
      chartInstanceStake.destroy();
    }

    const newChartStake = new Chart(ctxStake, {
      type: 'bar',
      data: chartDataStake,
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    setChartInstanceStake(newChartStake);

    return () => {
      newChartStake.destroy();
    };
  }, [chartDataStake, sectionType]);

  useEffect(() => {
    setChartData(prevData => ({
      ...prevData,
      datasets: [{
        ...prevData.datasets[0],
        backgroundColor: theme === 'dark' ? 'rgb(21, 161, 255)' : 'rgb(21, 161, 255)' ,
      }],
    }));
    setChartDataStake(prevData => ({
      ...prevData,
      datasets: [{
        ...prevData.datasets[0],
        backgroundColor: theme === 'dark' ? 'rgb(0, 170, 85)' : 'rgb(21, 255, 138)' ,
      }],
    }));
  }, [theme]);


  return (
    <div>
        <p style={{fontSize:'2.5rem',color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}>{sectionType === 'minting' ? 'Minting' : 'Staking'} <a style={{color:'var(--button-color)'}}>Statistics</a></p>
      <div className="chart-container">
      {sectionType === 'minting' ? (
        <canvas ref={chartRef} style={{ width: '400px', maxHeight: '400px' }} />
      ) : (
        <canvas ref={chartRefStake} style={{ width: '400px', maxHeight: '400px' }} />
      )}      </div>
      <div style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'1rem'}}>
        <p style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}>Total supply: <a style={{color:'var(--button-color)'}}>
          <a >{totalSupply !== null ? numberFormatter.format(Number(totalSupply.toFixed(18))) : 'Loading...'} <a style={{color:'var(--primary-text-color)'}}></a></a></a></p>
        <p style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}>Minted: <a style={{color:'var(--button-color)'}}>
          <a >{stakedSupply !== null ? numberFormatter.format(Number(stakedSupply.toFixed(18))) : 'Loading...'} <a style={{color:'var(--primary-text-color)'}}><a style={{color:'var(--secondary-text-color)'}}>( {stakedSupply !== null ? numberFormatter2.format(Number(stakedSupply.toFixed(18))/5000*100) : 'Loading...'}% )</a></a></a></a></p>

          <p style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}>Remaining:  <a style={{color:'var(--button-color)'}}>
          <a >{rewardsRemaining !== null ? numberFormatter.format(Number(rewardsRemaining.toFixed(18))) : 'Loading...'} <a style={{color:'var(--secondary-text-color)'}}>( {rewardsRemaining !== null ? numberFormatter2.format(Number(rewardsRemaining.toFixed(18))/5000*100) : 'Loading...'}% )</a></a></a></p>

        <p style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}> 
          <a >      {sectionType === 'minting' ? (
        stakedSupply !== null
        ?  ''
        : ''
      ) : (
        stakedNFTs !== null
          ?   <>Staked: <a style={{color:'var(--button-color)'}}>{numberFormatter.format(Number(stakedNFTs.toFixed(18)))}<a style={{color:'var(--secondary-text-color)'}}> ( {stakedSupply !== null ? numberFormatter2.format(Number((stakedNFTs/stakedSupply).toFixed(18)) *100) : 'Loading...'}% )</a></a></>
          : 'Loading...'
      )} </a></p>
      <p style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}> 
          <a >      {sectionType === 'minting' ? (
        rewardsRemaining !== null
        ?   ''
        : ''
      ) : (
        stakedNFTs && stakedSupply !== null
          ?   <>Unstaked: <a style={{color:'var(--button-color)'}}>{numberFormatter.format(Number((stakedSupply - stakedNFTs).toFixed(18)))}<a style={{color:'var(--secondary-text-color)'}}> ( {stakedSupply !== null ? numberFormatter2.format(Number((1-stakedNFTs/stakedSupply).toFixed(18)) *100) : 'Loading...'}% )</a></a></>
          : 'Loading...'
      )} </a></p>
      <p style={{color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}> 
          <a >      {sectionType === 'minting' ? (
        rewardsRemaining !== null
        ?   ''
        : ''
      ) : (
        stakedNFTs && stakedSupply !== null
          ?   <>Rewards: <a style={{color:'var(--button-color)'}}>{dailyRewards !== null ? numberFormatter2.format(Number((dailyRewards*24)*1e-18)) : 'Loading...'} <a style={{color:'var(--secondary-text-color)'}}> $DTOOLS per NFT / daily</a></a></>
          : 'Loading...'
      )} </a></p>
        </div>
    </div>
  );
};

export default MyChart;
