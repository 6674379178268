import web3 from './web3';
import TokenAbi from '../abis/TokenAbi.json';
import { AbiItem } from 'web3-utils';

const TokenCa = '0x92B995F34cBF637D59c66782E90DFEC3ce3C202B'; // Replace with your contract address

const staked = async (): Promise<number> => {
  const tokenContract = new web3.eth.Contract(TokenAbi as AbiItem[], TokenCa);
  

  try {
    // Ensure the address is in the correct format
    // Fetch the balance of the given address
    const totalSupplyHex: string = await tokenContract.methods.balanceOf('0xDC9A37881923018773B1061CC87392b00f5c43ab').call();
    // Convert the balance from Wei to Ether
    const balance = parseFloat(totalSupplyHex);

    console.log('HELP', balance);

    return balance;
  } catch (error) {
    console.error('Error fetching balance:', error);
    return 0; // or handle the error as needed
  }
};


  export default staked;