import web3 from './web3';
import TokenAbi from '../abis/TokenAbi.json';
import { AbiItem } from 'web3-utils';

const TokenCa = '0x92B995F34cBF637D59c66782E90DFEC3ce3C202B'; // Replace with your contract address


const remainingSupply = async (): Promise<number> => {
    const tokenContract = new web3.eth.Contract(TokenAbi as AbiItem[], TokenCa);
    try {
      const totalSupply = 5000;
      const burnedSupply: string = await tokenContract.methods.totalSupply().call();
      const burnedSupplyFormatted = parseFloat(burnedSupply);
      const remainingSupply = totalSupply - burnedSupplyFormatted;
      console.log('HELP', remainingSupply);
  
      return remainingSupply;
    } catch (error) {
      console.error('Error fetching remaining supply:', error);
      return 0; // or handle the error as needed
    }
  };

export default remainingSupply;