import React, { useState } from 'react';
import './QASection.css';

interface QAItem {
  question: string;
  answer: string;
}

interface TerminalProps {
  sectionType: 'minting' | 'staking';
}

interface QASectionProps extends TerminalProps {
  qaItems: QAItem[];
  qaItems1: QAItem[];
}

const QASection: React.FC<QASectionProps> = ({ sectionType, qaItems, qaItems1 }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Determine which QA items to display based on sectionType
  const itemsToDisplay = sectionType === 'minting' ? qaItems : qaItems1;

  return (
    <div>
      <p style={{fontSize:'2.5rem',color:'var(--primary-text-color)',marginBottom:'1rem', marginTop:'0'}}>
        Frequently asked <a style={{color:'var(--button-color)'}}>questions</a>
      </p>
      <div className="qa-section">
        {itemsToDisplay.map((item, index) => (
          <div key={index} className="qa-item">
            <div className="qa-question" onClick={() => toggleAnswer(index)}>
              {item.question}
              <span className="qa-arrow">{openIndex === index ? '▲' : '▼'}</span>
            </div>
            {openIndex === index && (
              <div className="qa-answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QASection;
