import { useEffect, useState } from 'react';
import '../styles/Home.moduls.css';
import totalSupplyformat from '../utils/totalSupply';
import remainingSupply from '../utils/remainingSupply';
import MarketCap from '../utils/marketCap';
import Minted from '../utils/minted';

// Number formatter for locale-specific formatting
const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const numberFormatter2 = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
});

// Define the type for fetched data
interface FetchedData {
  staked: number;
  rewards: number;
  yourStake: number;
}

interface NameProps {
  sectionType: 'minting' | 'staking';
}

const Name: React.FC<NameProps> = ({ sectionType }) => {
  const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const [remainingSupplyValue, setRemainingSupplyValue] = useState<number | null>(null);
  const [marketCap, setMarketCap] = useState<number | null>(null);
  const [tokenPrice, setTokenPrice] = useState<number | null>(null);

  useEffect(() => {
    const fetchTotalSupply = async () => {
      try {
        const supply = await totalSupplyformat();
        setTotalSupply(supply);
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };

    fetchTotalSupply();
  }, []);

  useEffect(() => {
    const fetchRemainingSupply = async () => {
      try {
        const supply2 = await remainingSupply();
        setRemainingSupplyValue(supply2);
      } catch (error) {
        console.error('Error fetching remaining supply:', error);
      }
    };

    fetchRemainingSupply();
  }, []);

  useEffect(() => {
    const fetchMarketCap = async () => {
      try {
        const { totalSupplyFormatted, costHexFormatted } = await Minted();
        setMarketCap(totalSupplyFormatted);
        setTokenPrice(costHexFormatted);
      } catch (error) {
        console.error('Error fetching remaining supply:', error);
        setMarketCap(0);
        setTokenPrice(0);
      }
    };

    fetchMarketCap();
  }, []);


  return (
    tokenPrice !== null && tokenPrice < 80 ? (
      <div className='name' style={{ marginTop: '100px', padding: '1.5rem' }}>
        <p style={{ fontSize: '3.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem' }}>
          NFT <a style={{ color: 'var(--button-color)' }}>{sectionType === 'minting' ? 'Minting' : 'Staking'}</a>
        </p>
      </div>
    ) : (
      <div className='name' style={{ marginTop: '70px', padding: '1.5rem'  }}>
        <p style={{ fontSize: '3.5rem', color: 'var(--primary-text-color)', marginBottom: '1rem', marginTop: '1rem' }}>
          NFT <a style={{ color: 'var(--button-color)' }}>{sectionType === 'minting' ? 'Minting' : 'Staking'}</a>
        </p>
      </div>
    )
  );
};

export default Name;
